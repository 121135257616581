<template lang="html">
  <div>
    <vx-card actionable class="cardx" title="Attendance(CMA)">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="10">
          <!-- <vs-select
            class="selectExample"
            label="Filter"
            v-model="select1"
            >
            <vs-select-item :key="index" :value="item.label" :text="item.label" v-for="(item,index) in options1" />
          </vs-select> -->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="2">
          <vs-button color="primary" size="small" type="filled" @click="openpop()" style="margin-right: 1%">ADD</vs-button>
        </vs-col>
      </vs-row><br>
      <!-- <vs-button color="primary" radius type="filled"  style="padding: 7px 7px 3px; float: right; margin-bottom: 2%"><feather-icon icon="PlusCircleIcon"></feather-icon> </vs-button> -->
      <vs-table
        max-item="10"       
        :data="attendanceTableData">
        <template slot="thead">
          <vs-th>
            Date
          </vs-th>
          <vs-th>
            Venue
          </vs-th>
          <vs-th>
            Cource
          </vs-th>
          <vs-th>
            Topic
          </vs-th>
          <vs-th>
            Trainer
          </vs-th>
          <vs-th>
            EWB Student Count
          </vs-th>
          <vs-th>
            Demos Count
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
    <vs-td :data="data[indextr].date">{{data[indextr].date}}</vs-td>

    <vs-td :data="data[indextr].venue">{{data[indextr].venue}}</vs-td>

    <vs-td :data="data[indextr].Cource">{{data[indextr].Cource}}</vs-td>

    <vs-td :data="data[indextr].topic">{{data[indextr].topic}}</vs-td>

    <vs-td :data="data[indextr].trainer">{{data[indextr].trainer}}</vs-td>

    <vs-td :data="data[indextr].ewbStudentCount">{{data[indextr].ewbStudentCount}}</vs-td>
    <vs-td :data="data[indextr].demo">{{data[indextr].demo}}</vs-td>
    <vs-td>
      <vs-button
        color="primary"
        radius
        type="filled"
        @click="openpop2()"
        style="padding: 7px 7px 3px; margin-bottom: 2%"
      >
        <feather-icon icon="PlusCircleIcon"></feather-icon>
      </vs-button>
    </vs-td>
  </vs-tr>
</template>
      </vs-table>
      <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-pagination :total="10" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import EventBus from "../components/eventbus.js";
export default {
  data: () => ({
    currentTablePage: 1,
    value1: "",
    value2: "",
    attendanceTableData: [
      {
        date: "01-06-2019",
        venue: "A-Block",
        Cource: "CPA",
        topic: "FAR",
        trainer: "jayashre",
        ewbStudentCount: 50,
        demo: 5
      },
      {
        date: "02-06-2019",
        venue: "B-Block",
        Cource: "CMA",
        topic: "AUD",
        trainer: "ankur",
        ewbStudentCount: 40,
        demo: 4
      },
      {
        date: "03-06-2019",
        venue: "D-Block",
        Cource: "CPA",
        topic: "BEC",
        trainer: "shekaran",
        ewbStudentCount: 30,
        demo: 9
      },
      {
        date: "04-06-2019",
        venue: "C-Block",
        Cource: "CMA",
        topic: "REG",
        trainer: "Hari",
        ewbStudentCount: 30,
        demo: 5
      },
      {
        date: "05-06-2019",
        venue: "A-Block",
        Cource: "CPA",
        topic: "AUD",
        trainer: "Karan",
        ewbStudentCount: 50,
        demo: 4
      },
      {
        date: "06-06-2019",
        venue: "A-Block",
        Cource: "CMA",
        topic: "BEC",
        trainer: "Jayadev",
        ewbStudentCount: 40,
        demo: 8
      },
      {
        date: "07-06-2019",
        venue: "B-Block",
        Cource: "CPA",
        topic: "FAR",
        trainer: "Mohan",
        ewbStudentCount: 60,
        demo: 3
      },
      {
        date: "08-06-2019",
        venue: "C-Block",
        Cource: "CMA",
        topic: "REG",
        trainer: "gunashekaran",
        ewbStudentCount: 30,
        demo: 7
      },
      {
        date: "09-06-2019",
        venue: "B-Block",
        Cource: "CPA",
        topic: "AUD",
        trainer: "naren",
        ewbStudentCount: 20,
        demo: 5
      },
      {
        date: "10-06-2019",
        venue: "A-Block",
        Cource: "CPA",
        topic: "FAR",
        trainer: "rajashekaran",
        ewbStudentCount: 50,
        demo: 4
      },
      {
        date: "11-06-2019",
        venue: "C-Block",
        Cource: "CMA",
        topic: "REG",
        trainer: "kiran",
        ewbStudentCount: 30,
        demo: 3
      },
      {
        date: "12-06-2019",
        venue: "A-Block",
        Cource: "CPA",
        topic: "BEC",
        trainer: "suren",
        ewbStudentCount: 50,
        demo: 4
      },
      {
        date: "13-06-2019",
        venue: "C-Block",
        Cource: "CMA",
        topic: "AUD",
        trainer: "venkat",
        ewbStudentCount: 30,
        demo: 3
      }
    ]
  }),
  mounted() {
    // this.mountedfunc()
  },
  methods: {
    // mountedfunc() {
    //   this.attendanceData = []
    //   this.$store.state.candidate_attendance.forEach((attend) => {
    //     this.attendanceData.push(attend)
    //   })
    // },
    // search(type) {
    //   this.value1 = ''
    //   this.value2 = ''
    //   if (type === 'name') {
    //     this.attendanceData = []
    //     this.$store.state.candidate_attendance.forEach((attend) => {
    //       if (attend.candidate_name === this.value1) {
    //         this.attendanceData.push(attend)
    //       }
    //     })
    //   } else if (type === 'batch') {
    //     this.attendanceData = []
    //     this.$store.state.candidate_attendance.forEach((attend) => {
    //       if (attend.batch === this.value2) {
    //         this.attendanceData.push(attend)
    //       }
    //     })
    //   }
    // },
    // clear() {
    //   this.value1 = ''
    //   this.value2 = ''
    // },
    openpop(obj) {
      EventBus.$emit("open-attendence-popup", obj);
    },
    openpop2() {
      EventBus.$emit("open-attendanceFillingForm-popup");
    }
  }
};
</script>